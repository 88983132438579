import React, { PureComponent } from 'react'
import { func, number, object, string } from 'prop-types'
import Print from 'microcomponents/print-button'
import styled from '@emotion/styled'
import { white } from 'src/helpers/css-variables'

export default class OrderDisplayInfo extends PureComponent {
  static propTypes = {
    order: object,
    orderId: number,
    printFn: func,
    setOrderFocus: func,
    row1Text: string,
    row1Label: string,
    row2Text: string,
    row2Label: string,
    row3Text: string,
    row3Label: string,
    isPackingLane: Boolean,
    isRunnerLane: Boolean,
    displayPrinter: Boolean,
    allowOrderDetails: Boolean,
    deliveriesRoute: string
  }

  state = {
    hovering: false
  }

  render () {
    const {
      orderId,
      order,
      printFn,
      setOrderFocus,
      row1Text,
      row1Label,
      row2Text,
      row2Label,
      row3Text,
      row3Label,
      isPackingLane,
      isRunnerLane,
      displayPrinter,
      allowOrderDetails,
      deliveriesRoute
    } = this.props
    const { hovering } = this.state
    return (
      <div onMouseEnter={() => this.setState({ hovering: true })}
        onMouseLeave={() => this.setState({ hovering: false })}
        >
        <LargeTextContainer>
        {hovering && isPackingLane && displayPrinter
          ? <Print onClick={() => printFn(orderId)} />
          : null
        }
        {displayPrinter
          ? isRunnerLane
            ? <LargeTextLink target='_blank' rel='noreferrer' href={deliveriesRoute}>{`${row1Label}: ${row1Text}`}</LargeTextLink>
            : <LargeText useCursorPointer onClick={allowOrderDetails ? () => setOrderFocus(order) : null}>{`${row1Label} ${row1Text}`}</LargeText>
          : <LargeText>{`${row1Label} ${row1Text}`}</LargeText>
        }
        {row2Label
          ? (
          <>
            <LargeText>{`${row2Label} ${row2Text}`}</LargeText>
          </>
            )
          : null}
        {row3Label
          ? (
          <>
            <LargeText>{`${row3Label} ${row3Text}`}</LargeText>
          </>)
          : null}
          </LargeTextContainer>
      </div>
    )
  }
}

const LargeTextContainer = styled.div`
  color: ${white};
  display: inline-block;
  font-size: 1.5rem;
  margin-right: 3rem;
  min-width: 17rem;
`

const LargeText = styled.div`
  ${({ useCursorPointer }) => useCursorPointer && 'cursor: pointer'};
`

const LargeTextLink = styled.a`
  color: ${white};
  overflow-x: hidden;
  text-decoration: none;
  text-overflow: ellipsis;
  width: 8rem;
`
