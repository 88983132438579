import React, { PureComponent } from 'react'
import { array, func, number, shape, string } from 'prop-types'
import OrderCardAction from '../order-card-action'
import OrderDisplayInfo from '../order-display-info'
import { LANE_TYPES } from 'helpers/hub'
import { mcGreen, mcBlue, mcRed, mcYellow } from 'src/helpers/css-variables'
import styled from '@emotion/styled'
import ROUTES from 'components/router/routes'
import { dateOnly, timeOnly, transformToUTCISOString } from 'src/helpers/date'
import { utcToZonedTime } from 'date-fns-tz'
import { selectDay } from 'src/helpers/delivery-fee-ranges'
export default class OrderCard extends PureComponent {
  static defaultProps = {
    onClickFn: () => {},
    printFn: () => {}
  }

  static propTypes = {
    onClickFn: func,
    orders: array,
    order: shape({
      id: number.isRequired,
      products: array.isRequired
    }),
    printFn: func,
    setOrderFocus: func,
    type: string.isRequired
  }

  getColor = () => {
    let color = mcGreen
    const { order = {} } = this.props

    if (order.deliveryWindowId) return mcBlue
    if (!order.createdAt) return color

    const orderCreatedAt = Date.parse(transformToUTCISOString(order.createdAt))
    const timeDeltaSeconds = (Date.now() - orderCreatedAt) / 1000

    // lol 7 minutes in seconds is 420
    if (timeDeltaSeconds >= 420 && timeDeltaSeconds < 600) {
      color = mcYellow
    } else if (timeDeltaSeconds >= 600) {
      color = mcRed
    }

    return color
  }

  render () {
    const {
      type,
      onClickFn,
      order = {},
      orders = [],
      printFn,
      setOrderFocus
    } = this.props
    const { id: orderId, loading: orderIsLoading, products = [] } = order

    let acceptedBy = {}
    let depotId
    let firstOrder = {}
    let itemQuantity = 0
    /*
    Drivers with canceled orders in their possession will have truthy packedById & manifestId.

    Otherwise, the order is still at the depot and just needs to be unpacked and restocked.
    In this case, manifestId will be null.

    Only show the driver's name on canceled order card if canceled order is in driver's possession.
    */

    let deliveryWindowString = 'ASAP'
    const isRunnerLane = type === LANE_TYPES.AWAITING_DRIVER
    // the following code can only be performed if the order is not in the runner lane and there is a delivery window, otherwise ist is an on demand order.
    if (!isRunnerLane) {
      if (order.deliveryWindowInfo) {
        const startISO = transformToUTCISOString(
          order.deliveryWindowInfo.timeFrom
        )
        const endISO = transformToUTCISOString(order.deliveryWindowInfo.timeTo)

        // clip the date off and use today's date to know if it's DST
        let start = `${dateOnly()}T${startISO.split('T')[1]}`
        let end = `${dateOnly()}T${endISO.split('T')[1]}`

        start = utcToZonedTime(start, order.depotTimeZone).toISOString()
        end = utcToZonedTime(end, order.depotTimeZone).toISOString()
        deliveryWindowString = `${timeOnly(start)} - ${timeOnly(end)}`
        deliveryWindowString = `${selectDay[
          order.deliveryWindowInfo.dayOfWeek
        ].label.substring(0, 2)} ${timeOnly(start)}-${timeOnly(end)}`
      }
    }

    const allowOrderDetails =
      !isRunnerLane ||
      type === LANE_TYPES.NOT_RECOVERED ||
      type === LANE_TYPES.RECOVERED
    const deliveriesRoute = `${ROUTES.DASHBOARD}/${depotId}${ROUTES.DELIVERIES}?driver=${order.driverId}`
    let driverPhone = ''
    if (isRunnerLane) {
      firstOrder = orders[0]
      acceptedBy = firstOrder.acceptedBy
      depotId = firstOrder.depotId
      driverPhone = acceptedBy.mobilePhoneDisplay
    } else {
      itemQuantity = products.reduce((a, product) => a + product.quantity, 0)
    }

    return (
      <OrderCardContainer color={this.getColor()}>
        <OrderDisplayInfo
          row1Text={orderId || acceptedBy.firstName}
          row1Label={isRunnerLane ? 'DRIVER: ' : 'ORDER NUMBER: '}
          row2Text={isRunnerLane ? driverPhone : deliveryWindowString}
          row2Label={isRunnerLane ? 'PHONE#: ' : 'WINDOW: '}
          row3Label={isRunnerLane ? 'ORDERS: ' : 'ITEMS:'}
          row3Text={isRunnerLane ? orders.length : itemQuantity}
          order={order}
          setOrderFocus={setOrderFocus}
          type={type}
          allowOrderDetails={allowOrderDetails}
          isRunnerLane={isRunnerLane}
          ispackingLane={type === LANE_TYPES.PACKING}
          deliveriesRoute={deliveriesRoute}
          displayPrinter={true}
        />
        <OrderDisplayInfo
          row1Text={isRunnerLane ? firstOrder.manifestId : order.createdBy.lastName}
          row1Label={isRunnerLane ? 'Manifest#: ' : 'CUSTOMER: '}
          row2Text={isRunnerLane ? '' : order.delivery.addressLine1}
          row2Label={isRunnerLane ? '' : 'ADDRESS: '}
          row3Label={isRunnerLane ? '' : 'CITY: '}
          row3Text={isRunnerLane ? '' : order.delivery.cityName}
          order={order}
          orderId={orderId}
          printFn={printFn}
          type={type}
          displayPrinter={false}
        />
        <OrderCardAction
          onClickFn={onClickFn}
          orderId={orderId}
          orderIsLoading={orderIsLoading}
          orders={orders}
          type={type}
        />
      </OrderCardContainer>
    )
  }
}

const OrderCardContainer = styled.div`
  align-items: center;
  border-radius: 1rem;
  background-color: ${({ color }) => color};
  display: flex;
  height: 10rem;
  margin-bottom: 1rem;
  padding: 2rem;
  position: relative;
`
